import React, { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  Alert, Button, Col, Divider, Result, Row, Space, Typography,
} from 'antd';
import { Link } from 'gatsby';
import { FcIdea } from 'react-icons/all';
import { AiOutlinePlus } from 'react-icons/ai';
import { FavouritesTable } from './favourites-table';
import { actWishId, actWishVariants } from '../../apollo/cache';
import { QUERY_PRODUCT_VARIANTS } from '../../graphql/queries/queryProductVariants';
import { Testimonials } from '../layout/sections/testimonials/testimonials';
import { WishlistInquiryCard } from '../cards/wishlist-inquiry-card/wishlist-inquiry-card';
import { CreateAccountCard } from '../cards/action-card/create-account-card';
import { StartSearchCard } from '../cards/action-card/start-search-card';
import { ViewFavouritesCard } from '../cards/action-card/view-favourites-card';
import { CenteredSection } from '../layout/sections/centered/centered-section';
import { inMemoryAuth } from '../auth/in-memory-auth';

const {
  Title,
} = Typography;

export const Favourites = () => {
  const wishlistId = useReactiveVar(actWishId);
  const wishlistVariants = useReactiveVar(actWishVariants);

  const [selectedRefs, setSelectedRefs] = useState([]);

  const {
    data: productVariants,
    loading,
  } = useQuery(QUERY_PRODUCT_VARIANTS,
    {
      skip: wishlistVariants?.length === 0,
      fetchPolicy: 'cache-and-network',
      variables: {
        ids: wishlistVariants,
        first: wishlistVariants.length,
      },
    });

  const items = productVariants?.productVariants?.edges.map(({ node }) => node);

  const isEmpty = !wishlistId && wishlistVariants?.length === 0;

  if (isEmpty) {
    return (
      <CenteredSection>
        <Space direction="vertical">
          <Result
            icon={<FcIdea />}
            title={(
              <Title level={3} className="text-center">
                Let
                {'\''}
                s start planning!
              </Title>
            )}
            // subTitle={(
            //   <>
            //     <Paragraph>
            //       1. Create a name and date for your Event.
            //     </Paragraph>
            //     <Paragraph>
            //       2. Add your favorite suppliers to the event
            //     </Paragraph>
            //     <Paragraph>
            //       3. Contact them all with one click.
            //     </Paragraph>
            //   </>
            // )}
            extra={[
              <Link to="/search">
                <Button type="primary" size="large" icon={<AiOutlinePlus />}>
                  Find Suppliers
                </Button>
              </Link>,
            ]}
          />
          <Divider />
          <StartSearchCard />
          <ViewFavouritesCard />
          <CreateAccountCard />
        </Space>
      </CenteredSection>
    );
  }

  return (
    <Row gutter={[32, 32]}>
      <Col xs={24} lg={14} xl={16}>
        {inMemoryAuth.token && wishlistId
        && (
          <Alert
            description="Use the checkbox to select which suppliers receive your message."
            message="Tip: Select Suppliers"
            type="info"
            showIcon
          />
        )}
        <FavouritesTable
          data={items}
          loading={loading}
          setSelectedRefs={setSelectedRefs}
        />
        <Testimonials />
      </Col>
      <Col xs={24} lg={10} xl={8}>
        <WishlistInquiryCard selectedRefs={selectedRefs} />
      </Col>
    </Row>
  );
};

export default Favourites;
