import { gql } from '@apollo/client';

export const WISHLIST_MESSAGE_CREATE = gql`
    mutation WishlistMessageCreate($message: String!, $wishlist: ID!, $user: ID!){
        wishlistMessageCreate(input: {message: $message, wishlist: $wishlist, user: $user}) {
            wishlistMessage {
                id
            }
            wishlistErrors {
                field
                message
                code
            }
        }
    }`;
