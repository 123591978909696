/* eslint-disable */
import React from 'react';
import { Card, Typography } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import css from './testimonials.module.less';

const { Title } = Typography;
const { Meta } = Card;

export const Testimonials = () => {
  const { allFile } = useStaticQuery(graphql`
      query Logos  {
          allFile(filter: { relativeDirectory: { eq: "testimonials" } }) {
              nodes {
                  name
                  publicURL
              }
          }
      }
  `);

  return (
    <aside className={css.aside}>
      <Title level={4}>Trusted By</Title>
      <ul className={css.list}>
        {allFile.nodes.map((image) => (
          <li className={css.listItem} key={image.publicURL}>
            <img
              width={100}
              height={100}
              alt={`${image.name} logo`}
              src={image.publicURL}
            />
          </li>
        ))}
      </ul>
    </aside>
  );
};
