import { Card, Typography } from 'antd';
import React from 'react';
import { FcLike } from 'react-icons/all';
import { Link } from 'gatsby';

const { Title } = Typography;

export const ViewFavouritesCard = () => (
  <Card hoverable bordered={false}>
    <Link to="/user/favourites/">
      <Card.Meta
        avatar={<FcLike />}
        title={<Title level={4}>Save Favourites</Title>}
        description="Save your preferred suppliers for each city you work in.
        Sign up to create named lists, set dates, itineraries & share."
      />
    </Link>
  </Card>
);
