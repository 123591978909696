import React from 'react';
import { BaseLayout } from '../../../components/layout/base/base-layout';
import { Favourites } from '../../../components/favorites/favourites';
import { SEO } from '../../../components/layout/seo';

const FavouritesPage = () => (
  <BaseLayout>
    <SEO
      title="Event Supplier List"
    />
    <Favourites />
  </BaseLayout>
);

export default FavouritesPage;
