import { gql } from '@apollo/client';
import { fragmentAddress } from './fragmentAddress';

export const fragmentLocation = gql`
    fragment fragmentLocation on Location {
        id
        geography
        address {
            ...fragmentAddress
        }
    }
    ${fragmentAddress}
`;
