import React from 'react';
import { Button, Card, Col, Divider, Form, Input, message, notification, Row, Space, Spin, Tag } from 'antd';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { actWishId } from '../../../apollo/cache';
import { QUERY_WISHLIST } from '../../../graphql/queries/queryWishlist';
import { inMemoryAuth } from '../../auth/in-memory-auth';
import { BiMailSend } from 'react-icons/all';
import { WISHLIST_MESSAGE_CREATE } from '../../../graphql/mutations/wishlistMessageCreate';
import css from './wishlist-message.module.less';
import dayjs from 'dayjs';
import { CenteredSection } from '../../layout/sections/centered/centered-section';

const { TextArea } = Input;

export const WishlistMessages = ({ selectedRefs }) => {
  const wishlistId = useReactiveVar(actWishId);
  const [form] = Form.useForm();

  const {
    data,
    loading,
    refetch,
  } = useQuery(
    QUERY_WISHLIST,
    {
      skip: !wishlistId,
      fetchPolicy: 'cache-and-network',
      variables: { id: wishlistId },
    },
  );

  const [wishlistMessageCreate, { loading: loadingSend }] = useMutation(WISHLIST_MESSAGE_CREATE,
    {
      onCompleted: (data) => {
        if (data.wishlistMessageCreate?.wishlistErrors?.length === 0) {
          form.resetFields();
          notification.success({ message: 'Inquiry Sent', description: 'You will receive a reply by email. Suppliers typically respond in 1-2 days. ' });
          return refetch();
        }
        return data.wishlistMessageCreate?.wishlistErrors?.forEach(
          ((err) => message.error(`Oops... ${err.message}`)),
        );
      },
    });

  console.log(selectedRefs);
  return (
    <>
      <section className={css.chat}>
        {loading ?
          <CenteredSection><Spin size="large" /></CenteredSection> : data?.wishlist?.messages?.edges.map(({ node }) => (
            <Row className={`${css.message} ${node.user?.id === inMemoryAuth.user?.id && css.yours}`}
                 key={node.id}
                 gutter={[4, 4]}
                 justify={node.user?.id === inMemoryAuth.user?.id ? 'start' : 'end'}>
              <Col>
                <Card>
                  {node.message}
                  <Card.Meta description={dayjs(node.createdAt)
                    .format('DD/MM/YYYY')}>
                  </Card.Meta>
                </Card>
              </Col>
            </Row>
          ))}
      </section>
      <Divider />
      <Form
        form={form}
        size="large"
        layout="vertical"
        onFinish={(values) => wishlistMessageCreate({
          variables: {
            wishlist: wishlistId,
            user: inMemoryAuth.user.id,
            message: `${values.message}
            ----------------------
            ${selectedRefs.join(', ')}`,
          },
        })}
      >
        <Form.Item
          name="message"
          rules={[
            {
              min: 1,
              whitespace: false,
              required: true,
              message: 'Cannot be empty',
            },
          ]}
        >
          <TextArea autoSize={{
            minRows: 4,
            maxRows: 6,
          }} placeholder="Send an inquiry or describe the event..." />
        </Form.Item>

        <Space className="form-actions">
          <section>
            {selectedRefs.map((ref) => <Tag>{ref}</Tag>)}
          </section>
          <Form.Item>
            <Button htmlType="submit" icon={<BiMailSend />} loading={loadingSend}>
              Send
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </>
  );
};
