import { Card, Typography } from 'antd';
import React from 'react';
import { FcSearch } from 'react-icons/all';
import { Link } from 'gatsby';

const { Title } = Typography;

export const StartSearchCard = () => (
  <Card hoverable bordered={false}>
    <Link to="/search">
      <Card.Meta
        avatar={<FcSearch />}
        title={<Title level={4}>Use Powerful Search</Title>}
        description="The industry's search helps you find exactly
              what you need quickly. Filter by supplier type, location, and many more."
      />
    </Link>
  </Card>
);
