import { Image, Table, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'gatsby';
import css from './favourites-table.module.less';
import { FavouritesItemActions } from './favourites-item-actions';

const cols = [
  {
    dataIndex: ['product', 'thumbnail'],
    render: (colVal, row) => (
      <Image
        src={colVal.url}
        preview={false}
        alt={colVal.alt || `${row.product.name} thumbnail`}
        className={css.img}
      />
    ),
    key: 'image',
    width: 100,
    className: css.noPadding,
  },
  {
    title: 'Title',
    dataIndex: ['product', 'name'],
    key: 'productName',
    width: '100%',
    ellipsis: {
      showTitle: false,
    },
    render: (name, row) => (
      <Tooltip
        placement="topLeft"
        title={name}
      >
        <Link to={`/${row.product.productType.slug}/${row.product.slug}`}>
          {name}
        </Link>
      </Tooltip>
    ),
  },
  {
    title: 'Ref',
    dataIndex: 'sku',
    align: 'center',
    width: 150,
    key: 'sku',
    className: css.noPadding,
  },
  {
    title: 'Location',
    align: 'center',
    width: 110,
    dataIndex: ['product', 'location', 'address', 'city'],
    key: 'productLocation',
  },
  {
    align: 'center',
    key: 'actions',
    className: css.noPadding,
    width: 100,
    render: (row) => <FavouritesItemActions item={row} />,
  },
];

export const FavouritesTable = ({
  data,
  loading,
  setSelectedRefs,
}) => (
  <Table
    sticky
    rowSelection={{
      type: 'checkbox',
      columnWidth: '48px',
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRefs(selectedRowKeys);
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      getCheckboxProps: (record) => ({
        sku: record.sku,
      }),
    }}
    columns={cols}
    rowKey="sku"
    dataSource={data}
    scroll={{ x: 700 }}
    loading={loading}
    pagination={false}
  />
);

FavouritesTable.defaultProps = {
  isClientSize: true,
};
