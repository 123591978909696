import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import { AddToFavoritesButton } from './add-to-favourites-button/add-to-favourites-button';

export const FavouritesItemActions = ({ item }) => (
  <Space>
    <AddToFavoritesButton variantId={item.id} />
  </Space>
);

FavouritesItemActions.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    product: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  }).isRequired,
};
