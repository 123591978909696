import { gql } from '@apollo/client';
import { fragmentPageInfo } from '../fragments/fragmentPageInfo';
import { fragmentLocation } from '../fragments/fragmentLocation';

export const QUERY_PRODUCT_VARIANTS = gql`
    query productVariants (
        $ids: [ID],
        $first: Int) {
        productVariants(
            ids: $ids,
            first: $first) {
            pageInfo {
                ...fragmentPageInfo
            }
            edges {
                node {
                    id
                    name
                    sku
                    product {
                        id
                        name
                        slug
                        productType {
                            slug
                        }
                        thumbnail(size: 100) {
                            url
                            alt
                        }
                        location {
                            ...fragmentLocation
                        }
                    }
                    images {
                        url (size: 500)
                        alt
                    }
                }
            }
        }
    }
    ${fragmentPageInfo}
    ${fragmentLocation}
`;
