import { gql } from '@apollo/client';

export const QUERY_WISHLIST = gql`
    query Wishlist($id: ID!) {
        wishlist (id: $id) {
            id
            name
            messages (last: 100) {
                edges {
                    node {
                        id
                        user {
                            id
                        }
                        message
                        createdAt
                    }
                }
            }
            dateTimeRange
            isActive @client
        }
    }
`;
// import { fragmentLocation } from '../fragments/fragmentLocation';
// import { gql } from '@apollo/client';
//
// export const QUERY_WISHLIST = gql`
//     query Wishlist($id: ID!) {
//         wishlist (id: $id) {
//             id
//             name
//             messages {
//                 edges {
//                     node {
//                         id
//                         user
//                         message
//                         createdAt
//                     }
//                 }
//             }
//             dateTimeRange
//             isActive @client
//             productVariants {
//                 id
//                 name
//                 sku
//                 product {
//                     id
//                     name
//                     slug
//                     productType {
//                         slug
//                     }
//                     thumbnail(size: 100) {
//                         url
//                         alt
//                     }
//                     location {
//                         ...fragmentLocation
//                     }
//                 }
//                 images {
//                     url (size: 500)
//                     alt
//                 }
//             }
//         }
//     }
//     ${fragmentLocation}
// `;
