import React from 'react';
import {
  Alert, Card, Col, Row, Typography,
} from 'antd';
import { useReactiveVar } from '@apollo/client';
import css from './wishlist-inquiry-card.module.less';
import { AuthForm } from '../../auth/auth-form';
import { inMemoryAuth } from '../../auth/in-memory-auth';
import { actWishId } from '../../../apollo/cache';
import { WishlistCreateForm } from '../../user/wishlists/wishlist-create-form';
import { WishlistMessages } from './wishlist-messages';

const { Title } = Typography;

export const WishlistInquiryCard = ({ selectedRefs }) => {
  const wishlistId = useReactiveVar(actWishId);

  let step = (
    <WishlistMessages selectedRefs={selectedRefs} />
  );

  if (!inMemoryAuth.token) {
    step = (
      <>
        <Alert
          message="Step 1/3. Sign in"
          description="We need your contact details to be able to get in touch"
          showIcon
          type="info"
        />
        <AuthForm />
      </>
    );
  } else if (!wishlistId) {
    step = (
      <>
        <Alert
          message="Step 2/3. Save the favourites"
          description="Use the form below to create a new list, or navigate to the Account page to choose an existing one"
          type="info"
          showIcon
          closable
        />
        <WishlistCreateForm />
      </>
    );
  }

  return (
    <Card
      title={<Title level={3}>Contact Suppliers</Title>}
      className={css.card}
    >
      <Row justify="center">
        <Col flex={1}>
          {step}
        </Col>
      </Row>
    </Card>
  );
};
